<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <FormCardTitle
          v-model="form"
          :title="`${$route.params.id ? 'Editar' : 'Cadastrar'} Ícone`"
        />

        <FormCardItem title="Informações">
          <CCol sm="12">
            <CInput
              id="first"
              v-model="form.title"
              label="Título*"
              placeholder="Título"
              required
              :disabled="processing"
            />
          </CCol>
          <CCol lg="12">
            <FileUploader
              id="imageUpload"
              :max-files="1"
              :uploader-info="imageInfo"
              extensions="svg"
              accept-types="image/svg+xml"
              :max-height="128"
              :max-width="128"
              :medias="medias"
              :size-file="1024 * 1024 * 5"
            />
          </CCol>
        </FormCardItem>

        <CRow v-if="$route.params.id">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="$route.params.id"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
          >
            Limpar Alterações
          </a>
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import Service from '@/services/icons.service'
import CardLog from '@/components/ui/CardLog'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import FormCardTitle from '@/components/ui/Form/FormCardTitle'

import moment from 'moment'
import Modal from '@/components/ui/Modal'
import { cloneDeep } from 'lodash'

const defaultItem = () => ({
  title: '',
  main_image_id: '',
  status: true
})

export default {
  metaInfo: {
    title: 'Ícones',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: { CardLog, FileUploader, Modal, FormCardItem, FormCardTitle },

  data: () => ({
    item: defaultItem(),
    form: defaultItem(),
    log: {},
    isLoading: true,
    processing: false,
    redirect: true,
    modal: {
      show: false,
      title: '',
      message: '',
      color: ''
    },
    medias: [],
    imageInfo: {
      label: 'Ícone',
      description: '(Formato SVG)'
    }
  }),

  async created() {
    this.setItem()
  },

  methods: {
    async setItem() {
      const { id } = this.$route.params

      if (id) {
        const data = await Service.show(id)

        if (data) {
          this.item = { ...data }
          this.medias = this.item.main_image

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.form = cloneDeep(this.item)
    },

    resetForm() {
      this.medias = []
      this.item = defaultItem()
      this.form = defaultItem()
      this.setItem()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      if (!this.medias.length) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Por favor preencha a imagem',
          color: 'danger'
        }
        return
      }

      this.processing = true

      let response
      let message

      const { id } = this.$route.params
      const [icon] = this.medias
      const data = { ...this.form, id, main_image_id: icon.id }

      if (id) {
        response = await Service.update(id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  }
}
</script>
